import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type ServiceWorkerScriptState from 'o365.pwa.modules.client.dexie.objectStores.ServiceWorkerScriptState.ts';
import type AppResourceState from 'o365.pwa.modules.client.dexie.objectStores.AppResourceState.ts';
import type { IServiceWorkerImportMap } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type * as ServiceWorkerStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';


export class ServiceWorkerState implements ServiceWorkerStateModule.ServiceWorkerState {
    static objectStoreDexieSchema: string = "&appId";

    appId!: string;
    requireServiceWorkerUpdate!: boolean;
    requiredServiceWorkerScripts!: Array<string | URL>;
    entrypoint!: string;
    importMap!: IServiceWorkerImportMap;
    readyForInstall!: boolean;
    installed!: boolean;
    failedToInstall!: boolean;

    public get serviceWorkerScriptStates() {
        const serviceWorkerState = this;

        return new Proxy<ServiceWorkerStateModule.ServiceWorkerScriptStates>(<ServiceWorkerStateModule.ServiceWorkerScriptStates>{
            getAll: async () => {
                return await IndexedDbHandler.getServiceWorkerScriptStates(serviceWorkerState.appId);
            }
        }, {
            get(target, prop, receiver) {
                if (prop in target) {
                    return Reflect.get(target, prop, receiver);
                }

                return new Promise<ServiceWorkerScriptState | null>(async (resolve, reject) => {
                    try {
                        const serviceWorkerScriptState = await IndexedDbHandler.getServiceWorkerScriptState(serviceWorkerState.appId, prop.toString());
                    
                        resolve(serviceWorkerScriptState);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }
        });
    }

    public get appResourceStates() {
        const serviceWorkerState = this;

        return new Proxy<ServiceWorkerStateModule.AppResourceStates>(<ServiceWorkerStateModule.AppResourceStates>{
            getAll: async () => {
                return await IndexedDbHandler.getAppResourceStates(serviceWorkerState.appId);
            }
        }, {
            get(target, prop, receiver) {
                if (prop in target) {
                    return Reflect.get(target, prop, receiver);
                }

                return new Promise<AppResourceState | null>(async (resolve, reject) => {
                    try {
                        const appResourceState = await IndexedDbHandler.getAppResourceState(serviceWorkerState.appId, prop.toString());
                    
                        resolve(appResourceState);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }
        });
    }

    constructor(options: ServiceWorkerStateModule.IServiceWorkerStateOptions) {
        this.appId = options.appId;
        this.entrypoint = options?.entrypoint ?? "o365.pwa.modules.sw.DefaultEntryPoint.ts";
        this.importMap = options.importMap ?? {};
        this.readyForInstall = false;
        this.installed = false;
        this.failedToInstall = false;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateServiceWorkerState(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteServiceWorkerState(this);
    }

    public async forceReload(): Promise<ServiceWorkerState | null> {
        return await IndexedDbHandler.getServiceWorkerStateFromIndexedDB(this.appId);
    }

    static clearCache(): void {
        IndexedDbHandler.clearServiceWorkerStateCache();
    }
}

export default ServiceWorkerState;
